import type { ImgHTMLAttributes, SyntheticEvent } from 'react'
import { useEffect } from 'react'
import { useCallback, useRef } from 'react'
import { image } from './LazyImage.css'

interface LazyImageProps extends ImgHTMLAttributes<HTMLImageElement> {
  fallbackSrc?: string
}

const LazyImage = (props: LazyImageProps) => {
  const imgRef = useRef<HTMLImageElement>(null)
  const { fallbackSrc, alt, className, loading, src, srcSet, sizes, ...rest } =
    props

  const onError = useCallback(
    ({ currentTarget }: SyntheticEvent<HTMLImageElement>) => {
      if (!fallbackSrc) {
        return
      }

      currentTarget.onerror = null

      if (imgRef.current) {
        imgRef.current.src = fallbackSrc
      }
    },
    [imgRef, fallbackSrc]
  )

  useEffect(() => {
    const imgElement = imgRef.current

    if (!imgElement) {
      return
    }

    const handleImageLoad = () => {
      imgElement.setAttribute('data-image-loaded', 'true')
    }

    // 이미지가 이미 로드된 상태인지 확인
    if (imgElement.complete) {
      handleImageLoad()
      return
    }

    imgElement.addEventListener('load', handleImageLoad)

    return () => {
      imgElement.removeEventListener('load', handleImageLoad)
    }
  }, [])

  return (
    <img
      ref={imgRef}
      loading={loading || 'lazy'}
      className={[image, className].join(' ')}
      data-image-loaded={'false'}
      onError={onError}
      alt={alt}
      sizes={sizes}
      srcSet={srcSet}
      src={src}
      {...rest}
    />
  )
}

export default LazyImage
